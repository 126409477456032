import React from "react";
import AccountDropdown from "./AccountDropdown";
import DarkModeToggle from "./DarkModeToggle";
import UserInfo from "./UserInfo";
import FlowLogo from "../../../icons/FlowLogo";
import CustomButton from "./CustomButton";

const NavBar = ({
  accounts,
  selectedAccount,
  onAccountChange,
  isDarkMode,
  toggleDarkMode,
  userInfo,
  onLogout,
}) => {
  console.log(userInfo);
  return (
    <nav className="flex flex-col justify-between items-center p-4 bg-white dark:bg-flowDark w-full">
      <div className="flex justify-between w-full border-b dark:border-white/10 border-black/10 pb-10">
        <div className="max-w-[128px]">
          <FlowLogo />
        </div>
        <div className="flex flex-row gap-3 items-center">
          <DarkModeToggle
            isDarkMode={isDarkMode}
            toggleDarkMode={toggleDarkMode}
          />
          <CustomButton text={"Termin vereinbaren"} />
          <UserInfo userInfo={userInfo} onLogout={onLogout} />
        </div>
      </div>
      <div className="flex items-center space-x-4 pt-9 pb-14 w-full">
        <div className="flex lg:flex-row flex-col justify-between lg:items-center w-full">
          <div className="text-[#333] dark:text-white flex-col justify-start items-start">
            <h2 className="text-[32px]">
              {userInfo
                ? `Hello ${userInfo.firstName} ${userInfo.lastName.charAt(
                    0
                  )}. 👋`
                : "Hello Guest 👋"}
            </h2>
            <p className="tracking-[0.64px] dark:text-white/80">
              Willkommen beim FLOW Audit Tool
            </p>
          </div>
          <div className="flex flex-col items-start justify-start">
            <p className="text-[#333] dark:text-white text-sm leading-5 tracking-normal">
              Ads Konto
            </p>
            <AccountDropdown
              accounts={accounts}
              selectedAccount={selectedAccount}
              onAccountChange={onAccountChange}
            />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
