import React from "react";
import { LogIn, Settings, Link as LinkIcon, Cog } from "lucide-react";

const SetupSteps = () => {
  const steps = [
    {
      icon: <LogIn className="w-6 h-6" />,
      title: "Login mit Google",
      description: "Verbinden Sie sich mit Ihrem Google-Konto",
    },
    {
      icon: <Settings className="w-6 h-6" />,
      title: "Login Popup bestätigen",
      description: "Folgen Sie den Anweisungen im Login-Popup",
    },
    {
      icon: <LinkIcon className="w-6 h-6" />,
      title: "Ads Account verbinden",
      description: "Verbinden Sie Ihr Google Ads Konto",
    },
    {
      icon: <Cog className="w-6 h-6" />,
      title: "Servereinrichtung bestätigen",
      description: "Die Server-Einstellungen werden automatisch vorgenommen",
    },
  ];

  return (
    <div className="w-full max-w-4xl mx-auto mt-8">
      <h2 className="text-xl font-semibold mb-2">
        Nächste Schritte zur Einrichtung
      </h2>
      <p className="text-gray-600 dark:text-gray-400 mb-6">
        Wir führen Sie durch die folgenden Schritte
      </p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
        {steps.map((step, index) => (
          <div
            key={index}
            className="p-8 bg-[#1a1b1d] rounded-3xl shadow border border-[#5e5e5e] flex-col justify-start items-start inline-flex"
          >
            <div className="w-12 h-12 rounded-full bg-blue-100 dark:bg-blue-900 flex items-center justify-center mb-3">
              {step.icon}
            </div>
            <h3 className="text-white text-base font-medium font-inter leading-tight tracking-wide">
              {step.title}
            </h3>
            <p className="text-white text-xs font-normal font-inter leading-tight tracking-wide">
              {step.description}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SetupSteps;
