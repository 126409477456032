import React from "react";
import SummarySection from "./SummaryMeter";
import KpiCard from "./KpiCard";

const KpisAndSummary = ({ analysisData, dashboardData }) => {
  if (!dashboardData || !analysisData) {
    return <div>Loading data...</div>;
  }

  console.log(dashboardData, "INSIDE KpisAndSummary");
  const campaigns =
    dashboardData.campaigns || dashboardData.dashboardData.campaigns || [];

  // Helper function to format Euro values
  const formatEuro = (value) => {
    return (
      value.toLocaleString("de-DE", {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }) + "€"
    );
  };

  // Calculate KPIs
  const conversionValue = campaigns.reduce(
    (sum, campaign) => sum + (campaign.conversions_value || 0),
    0
  );
  const clicks = campaigns.reduce(
    (sum, campaign) => sum + (campaign.clicks || 0),
    0
  );
  const impressions = campaigns.reduce(
    (sum, campaign) => sum + (campaign.impressions || 0),
    0
  );
  const ctr = impressions > 0 ? (clicks / impressions) * 100 : 0;
  const cost = campaigns.reduce(
    (sum, campaign) => sum + (campaign.cost_micros || 0),
    0
  );
  const roas = cost > 0 ? conversionValue / cost : 0;
  const conversions = campaigns.reduce(
    (sum, campaign) => sum + (campaign.conversions || 0),
    0
  );
  const avgConvValue = conversions > 0 ? conversionValue / conversions : 0;

  return (
    <div className="flex flex-col lg:flex-row items-start gap-4">
      <div className=" lg:grid-cols-2 flex-wrap justify-start lg:max-w-[38%] gap-3 w-full lg:w-auto items-start grid grid-cols-1">
        <KpiCard
          title="Conversion Wert"
          value={formatEuro(conversionValue)}
          change={11.01}
          info="Wie verbessern?"
          expandInfo="Steigere den Conversion-Wert, indem du weitere Gebotsstrategien testest, wie Conversion-Wert maximieren oder Ziel-ROAS. Achte auch auf deine Landingpages und optimiere auf höhere Warenkorbwerte oder integriere zusätzliche Up- und Cross-Selling-Angebote."
        />
        <KpiCard
          title="CTR"
          value={`${ctr.toFixed(2)}%`}
          change={11.01}
          info="Wie verbessern?"
          expandInfo="Erhöhe die CTR durch präzise Keywords, relevante Anzeigentexte und klare USPs. Nutze zudem Anzeigenerweiterungen (neu: Assets) wie Sitelinks oder Callouts, um mehr Aufmerksamkeit zu erzielen.
"
        />
        <KpiCard
          title="ROAS"
          value={roas.toFixed(2)}
          change={11.01}
          info="Wie verbessern?"
          expandInfo="Verbessere deinen ROAS, indem du unprofitable Keywords oder Produkte ausschließt, Gebote für gut performende Assets erhöhst und gezieltere Zielgruppen ansprichst. Splitte die Kampagnen oder Anzeigengruppen nach Margen auf, um mit unterschiedlichen Geboten zu arbeiten."
        />
        <KpiCard
          title="Impressionen"
          value={impressions.toLocaleString("de-DE")}
          change={11.01}
          info="Wie verbessern?"
          expandInfo="Erhöhe Impressionen durch breitere Keyword-Listen, höhere Gebote und das Erschließen zusätzlicher Werbenetzwerke wie das Display-Netzwerk oder die Nutzung des Kampagnentypes DemandGen oder PerformanceMax. Unmodifizierte Keywords bringen mehr Reichweite, aber achte dabei auf Streuverluste."
        />
        <KpiCard
          title="Durchschn. Conv.-Wert"
          value={formatEuro(avgConvValue)}
          change={-3.01}
          info="Wie verbessern?"
          expandInfo="Senke deine Kosten, indem du unwirksame Keywords pausierst, Budgets auf leistungsstarke Kampagnen konzentrierst und den Kampagnen weitere Signale, wie Zielgruppenlisten oder Werbezeiten hinzufügst. Ein kontinuierliches Monitoring hilft, Budgetverschwendung zu vermeiden."
        />
        <KpiCard
          title="Kosten"
          value={formatEuro(cost)}
          change={11.01}
          info="Wie verbessern?"
          expandInfo="Senke deine Kosten, indem du unwirksame Keywords pausierst, Budgets auf leistungsstarke Kampagnen konzentrierst und den Kampagnen weitere Signale, wie Zielgruppenlisten oder Werbezeiten hinzufügst. Ein kontinuierliches Monitoring hilft, Budgetverschwendung zu vermeiden."
        />
        <KpiCard
          title="Klicks"
          value={clicks.toLocaleString("de-DE")}
          change={11.01}
          info="Wie verbessern?"
          expandInfo="Mehr Klicks erhältst du durch ansprechende, relevante Anzeigentexte, eine verbesserte Anzeigenposition und aussagekräftige Calls-to-Action. Auch ein breiterer Keyword-Mix kann die Klickrate anheben."
        />
        <KpiCard
          title="Conversions"
          value={conversions.toLocaleString("de-DE")}
          change={-3.01}
          info="Wie verbessern?"
          expandInfo="Verbessere die Conversion-Rate durch gezielte Tests von Landingpages, vereinfachte Checkout-Prozesse und personalisierte Angebote. Auch klar strukturierte Inhalte und Vertrauen schaffende Elemente (z. B. Kundenbewertungen) wirken sich positiv aus."
        />
      </div>
      <div className="w-full lg:flex-1 flex-shrink-0">
        <SummarySection analysisData={analysisData} />
      </div>
    </div>
  );
};

export default KpisAndSummary;
