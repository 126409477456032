import React, { useState, useRef } from "react";
import ReactPlayer from "react-player";
import Modal from "react-modal";
import { FileText } from "lucide-react";
import AdsWhitepaper from "../../../assets/AdsWhitepaper.pdf";

const VideoAndWhitepaper = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [playedSeconds, setPlayedSeconds] = useState(0);
  const playerRef = useRef(null);

  const handleOpenModal = () => {
    setIsOpen(true);
  };

  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const handleProgress = (state) => {
    setPlayedSeconds(state.playedSeconds);
  };

  return (
    <div className="lg:py-28 flex flex-col lg:flex-row justify-between items-between gap-6">
      <div
        className="lg:h-[578px] h-auto lg:w-1/2 relative cursor-pointer overflow-hidden rounded-xl"
        onClick={handleOpenModal}
      >
        <div className="absolute inset-0 rounded-xl">
          <ReactPlayer
            url="/videos/Optimized_LP.mp4"
            width="100%"
            height="100%"
            playing={true}
            muted={true}
            loop={true}
            playsinline={true}
            ref={playerRef}
            onProgress={handleProgress}
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              objectFit: "cover",
              borderRadius: "12px",
            }}
          />
        </div>
      </div>

      <Modal
        isOpen={isOpen}
        onRequestClose={handleCloseModal}
        contentLabel="Video Lightbox"
        className="Modal"
        overlayClassName="Overlay"
      >
        <ReactPlayer
          url="/videos/Optimized_LP.mp4"
          width="100%"
          height="100%"
          playing={true}
          controls={true}
          playsinline={true}
          onProgress={handleProgress}
          progressInterval={1000}
          startTime={playedSeconds}
        />
        <button onClick={handleCloseModal} className="CloseButton">
          Close
        </button>
      </Modal>

      <div className="flex flex-col justify-between items-between lg:max-w-[50%] lg:h-[578px] gap-10">
        <h2 className="text-flowGray text-flow-36 font-light leading-snug dark:text-[#F6F5EE]">
          Entdecke die Macht von Google Ads mit unseren{" "}
          <span className="flowGradientText">individuellen Leistungen.</span>
        </h2>
        <p className="text-flowGray dark:text-[#F9F8F7] lg:text-flow-20 leading-snug tracking-[0.8px] font-light">
          Lörem ipsum deng redytrel, desfili sekint, respektive diast. Sakoska
          dysskop saning. Far. Pov homoning inte vigon emedan pros katrengar.
          Plusjobb såst non. Antilingar kvasint för ask synmani. Äsör kobelt
          krosat krosat. Migisk bende och ultraren. Faning multin timide rekrati
          onaktig. Apocentrism kontrapabelt jag naporad kost, då lött.
          Sms-livräddare såren tvillingshoppare kesat, hemirade. Nyskap novis
          åkapons och vavis. Dyr doras. Hemiheten spesm mikrojys. Poling
          kubtest. Propladade pedyst biotirad.
        </p>

        <a
          href="{AdsWhitepaper}"
          target="_blank"
          rel="noopener noreferrer"
          className="text-flowGray dark:text-flowWhite flex flex-row justify-center items-center gap-3 py-4 px-6 border border-[#34B2FF] hover:bg-[#34B2FF]/10 transition-colors duration-200 rounded-2xl max-w-[440px]"
        >
          <FileText className="w-4 h-4 stroke-flowGray dark:stroke-flowWhite" />
          <span>Öffne unser Google Ads Whitepaper</span>
        </a>
      </div>
    </div>
  );
};

export default VideoAndWhitepaper;
