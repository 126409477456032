import React, { useState, useEffect } from "react";
import KpisAndSummary from "../refactor/KpisAndSumary";
import QualityScoreCard from "../refactor/QualityScoreCard";
import Insights from "../refactor/Insights";
import Recommendations from "../refactor/Recommendations";
import ContactAndInfo from "../refactor/ContactAndInfo";
import VideoAndWhitepaper from "../refactor/VideoAndWhitepaper";
import MarqueeWithHeadline from "../refactor/MarqueeWithHeadline";
import GoogleAdsSlider from "../refactor/GoogleAdsSlider";
import {
  SkeletonKPI,
  SkeletonGraph,
  SkeletonInsight,
} from "../refactor/SkeletonLoarder";

const CustomTabs = ({ children, activeTab, setActiveTab }) => (
  <div className="custom-tabs">
    <div className="tab-buttons">
      {React.Children.map(children, (child, index) => (
        <button
          key={index}
          className={`tab-button ${activeTab === index ? "active" : ""}`}
          onClick={() => setActiveTab(index)}
        >
          {child.props.label}
        </button>
      ))}
    </div>
    <div className="tab-content">
      {React.Children.toArray(children)[activeTab]}
    </div>
  </div>
);

const ResponsiveInsightsTabs = ({ analysisData, isLoadingAnalysis }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 1024);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isDesktop) {
    return (
      <div className="grid lg:grid-cols-2 grid-cols-1 gap-8 h-full">
        <div className="h-full">
          {isLoadingAnalysis ? (
            <SkeletonInsight className="h-full" />
          ) : (
            <Insights analysisData={analysisData} className="h-full" />
          )}
        </div>
        <div className="h-full">
          {isLoadingAnalysis ? (
            <SkeletonInsight className="h-full" />
          ) : (
            <Recommendations analysisData={analysisData} className="h-full" />
          )}
        </div>
      </div>
    );
  }

  return (
    <CustomTabs activeTab={activeTab} setActiveTab={setActiveTab}>
      <div label="Insights">
        {isLoadingAnalysis ? (
          <SkeletonInsight />
        ) : (
          <Insights analysisData={analysisData} />
        )}
      </div>
      <div label="Recommendations">
        {isLoadingAnalysis ? (
          <SkeletonInsight />
        ) : (
          <Recommendations analysisData={analysisData} />
        )}
      </div>
    </CustomTabs>
  );
};

const AnalysisDashboard = ({
  analysisData,
  dashboardData,
  isLoadingDashboard,
  isLoadingAnalysis,
}) => {
  useEffect(() => {
    console.log("AnalysisDashboard mounted/updated", {
      analysisData,
      dashboardData,
      isLoadingDashboard,
      isLoadingAnalysis,
    });
  }, [analysisData, dashboardData, isLoadingDashboard, isLoadingAnalysis]);

  if (isLoadingDashboard || isLoadingAnalysis) {
    return <div>Loading...</div>;
  }

  if (!analysisData || !dashboardData) {
    return <div>No data available</div>;
  }

  // Determine the correct structure
  const analysisContent = analysisData.analysis || analysisData;
  const overallScore = analysisContent.overall_score || 0;

  return (
    <div>
      <div className="w-full space-y-16 lg:pb-28 border-b border-b-flowGray dark:border-b-[#5E5E5E]">
        <QualityScoreCard score={overallScore} />
        <KpisAndSummary
          analysisData={analysisContent}
          dashboardData={dashboardData}
        />
        <ResponsiveInsightsTabs
          analysisData={analysisContent}
          isLoadingAnalysis={isLoadingAnalysis}
        />
        <ContactAndInfo />
      </div>
      <VideoAndWhitepaper />
      <MarqueeWithHeadline />
      <GoogleAdsSlider />
    </div>
  );
};

export default AnalysisDashboard;
